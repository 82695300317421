import React from "react";
import classnames from "classnames";
import * as Cookies from "js-cookie";
import axios from 'axios';
import {Redirect, Link } from "react-router-dom";

import {
  Button,
  UncontrolledAlert,
  Alert,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Container,
  Badge,
  Row,
  Col
} from "reactstrap";

const messages = [
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Eickhoff",
    lastMessage: "Mau apa?"
  },
  {
    name: "Bagus",
    lastMessage: "Tidak mau saya"
  },
  {
    name: "Admin",
    lastMessage: "Tolong ya"
  },
  {
    name: "Yilmaz",
    lastMessage: "Bitte"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  }
];

const notification = [
  
];
const messageToReder = 3;

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      notification : [],
      msgLength: '',
      msgLengthJob: '',
      notificationJob : [],
     // msgLengthChat : '',
    };
  }

  
  logOut=(e)=>{
    axios.post("/api/signOut",  this.state)
    .then(response => { 

   })
   .catch(error => {
    console.log(error);
  })

    localStorage.clear();
    Cookies.remove('user_id1');
    this.setState({redirect : true})
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    const user_id = JSON.parse(localStorage.getItem('user_id'))
    this.setState({user_id})
    //console.log("user_id",user_id)
    axios.post("/api/getnotifications", {...this.state,user_id})
   .then(response => { // then print response status
    console.log(response)
    const msgLength = response.data.length;
    this.setState({msgLength});
    const notification = response.data;
    this.setState({notification})
  //  this.intervalID = setTimeout(this.componentDidMount.bind(this), 10000);
  })
  .catch(error => {
   //console.log(error);
   this.setState({notification: []})
    this.setState({msgLength: ''})
   //this.intervalID = setTimeout(this.componentDidMount.bind(this), 10000);
 })
 ////////////////////////////////////////////////////////
 axios.post("/api/getupdates", {...this.state,user_id})
   .then(response => { // then print response status
    console.log(response)
    const msgLengthJob = response.data.length;
    this.setState({msgLengthJob});
    const notificationJob = response.data;
    this.setState({notificationJob})
   // this.intervalID = setTimeout(this.componentDidMount.bind(this), 10000);
  })
  .catch(error => {
   //console.log(error);
   this.setState({notificationJob: []})
    this.setState({msgLengthJob: ''})
   //this.intervalID = setTimeout(this.componentDidMount.bind(this), 10000);
 })
 ///////////////////////////////////////////
//  axios.post("/api/getnotifications1", {...this.state,user_id})
//    .then(response => { // then print response status
//     //console.log(response)
//     if(response.data[0].cnt === 0){
//       this.setState({msgLengthChat: ''})
//     }else{
//       const msgLengthChat = response.data[0].cnt;
//       this.setState({msgLengthChat});
//     }
//   //   const notificationJob = response.data;
//   // this.setState({notificationJob})
//     //this.intervalID = setTimeout(this.componentDidMount.bind(this), 10000);
//   })
//   .catch(error => {
//    console.log(error);
//   //  this.setState({notificationJob: []})
//      this.setState({msgLengthChat: ''})
//    //this.intervalID = setTimeout(this.componentDidMount.bind(this), 10000);
//  })
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount(){
    const f_name = JSON.parse(localStorage.getItem('f_name'))
    this.setState({f_name})
    // const title = JSON.parse(localStorage.getItem('title'))
    // this.setState({title})
    const lname = JSON.parse(localStorage.getItem('lname'))
    this.setState({lname})
    const userprofile = JSON.parse(localStorage.getItem('userprofile'))
    this.setState({userprofile})
    const andre = JSON.parse(localStorage.getItem('andre'))
    this.setState({andre})
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  componentWillUnmount(){
    clearTimeout(this.intervalID);
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  handleRemove(id){
    this.state.notification.splice(id,1)
    this.setState({notification: this.state.notification})
  }
  render() {
  //  const [list, setList] = React.useState(notification);
  

    const { redirect } = this.state;
     if (redirect) {
       return <Redirect to='/login'/>;
     }
    const messageList = this.state.notificationJob.slice(0, 10).map((notificationJob, id) => (
    
      <div key={id}>
        <DropdownItem
          href={`/admin/details-jobs/${notificationJob.job_id}`}
          // onClick={e => e.preventDefault()}
        >
          <b>{notificationJob.company_name}</b> <br />
          <small>{notificationJob.job_function} {notificationJob.profession} </small><br></br>
            <small>Status: {notificationJob.candidate_status}</small> 
        </DropdownItem>

        <hr />
        </div>
    
    ));

    const notificationList = this.state.notification.map((notification, id) => (
        <div key={id}>
{/*        
        <Button type="button" onClick={() => this.handleRemove(id)}>
        <span style={{float:'right', marginRight:'22px', marginTop:'15px'}}>
          <i class="fas fa-times"></i>
          </span>
          </Button>
        */}
          <DropdownItem
         href={`/admin/messages-list/${notification.job_id}/${notification.hosp_id}`}
      
     // onClick={e => e.preventDefault()}
          >
            <b>{notification.hosp_name}</b> <br />
            <small>{notification.message}</small>
            
          </DropdownItem>

          <hr />
        </div>
      ));
    return (
      <>
       
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg">
            
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}>
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
                
              </div>
              {/* kjdhkjhgsdgjhsdgf  */}
             {this.state.f_name} 
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}>
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}>
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              {/* <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                <span className="d-none d-md-block"> Michelle Bachmann</span>
              </NavbarBrand> */}
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            {/* <Collapse
              className="justify-content-start"
              navbar
              isOpen={this.state.collapseOpen}>
               <Nav navbar>
               
                    <Button 
                      href="/admin/documents"
                      //block
                      className="btn-round mb-3"
                      color="primary"
                      //type = "submit"
                      // onClick={e => e.preventDefault()}
                    >
                      Dokument
                    </Button>
                                   
              </Nav>
              </Collapse> */}
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}>
              {/* <Form>
                <InputGroup className="no-border">
                  <Input defaultValue="" placeholder="Search..." type="text" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form> */}
                {/* <div style={{fontSize:'15px', marginRight:'28px'}}>
               <Link to="/admin/message/:job_id/:hosp_id">
                <Badge color="danger" 
                style={{
                  marginRight: '3px',
                  borderRadius: '50%',
                  verticalAlign: 'top',
                  fontSize: '10px',
                  padding: '0 5px',
                  width: '15px',
                  height: '15px',
                  lineHeight: '1.2',       
                }} 
                >{this.state.msgLengthChat}</Badge>
                    <i className="nc-icon nc-chat-33" 
                    style={{marginTop:'11px'}} />
                  </Link>
                  </div> */}
                 {/* <UncontrolledAlert color="success" fade={false}>
                          <Link to="/admin/referal">
                            <span style={{maxWidth:'100%'}}>
                          
                          Freunde werben 200 € kassieren
                            </span>
                            </Link>
                          </UncontrolledAlert> */}
              <Nav navbar>

                {/* <UncontrolledDropdown nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="success"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav>
                    <Badge color="danger">{this.state.msgLength}</Badge>
                    <i className="nc-icon nc-email-85" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                    
                      {notificationList}
                     
                      <Row>
                        <Col className="text-center" md="12">
                        <Link className="text-center">
                        <Button color="primary">Alles ansehen

                        </Button>
                      
                      </Link>
                        </Col>
                      </Row>
                      
                  </DropdownMenu>
                </UncontrolledDropdown> */}
             
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="success"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav>
                    <Badge color="danger">{this.state.msgLengthJob}</Badge>
                    <i className="nc-icon nc-bell-55" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                    
                      {messageList}
                      <Row>
                        <Col className="text-center" md="12">
                        <Link className="text-center">
                        {/* <Button color="primary">Alles ansehen

                        </Button>
                       */}
                      </Link>
                        </Col>
                      </Row>
                  </DropdownMenu>
                </UncontrolledDropdown>

              
{/*                
                    <p style={{marginTop: '8px'}}>
                    <Link 
                    to="/admin/feedback" 
                    >  
                    <b><u><h5
                    style = {{fontSize: '15px'}} >
                    Bewertung</h5>
                    </u></b>
                    </Link>
                    </p> */}
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav>
                    <i className="nc-icon nc-settings-gear-65" />
                  
                      <span className="d-lg-none d-md-block" style={{display:'none'}}>Actions</span>
                  
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                    <DropdownItem
                     tag={Link}
                     to="/admin/account"
                      // onClick={e => e.preventDefault()}
                    >
                      
                      Konto
                     </DropdownItem>
                   
                     <DropdownItem
                    tag={Link}
                    target="_blank"
                      to="/Impressum"
                      rel="noopener noreferrer"
                      // onClick={e => e.preventDefault()}
                    >
                      Impressum
                    </DropdownItem>
                    <DropdownItem
                    tag={Link}
                    target="_blank"
                      to="/kontakt"
                      rel="noopener noreferrer"
                      // onClick={e => e.preventDefault()}
                    >
                      Kontakt
                    </DropdownItem>
                    <DropdownItem
                    tag={Link}
                    target="_blank"
                      to="/Datenschutz"
                      rel="noopener noreferrer"
                      // onClick={e => e.preventDefault()}
                    >
                      Datenschutz
                    </DropdownItem>
                    <DropdownItem
                    tag={Link}
                    target="_blank"
                      to="/AGB"
                      rel="noopener noreferrer"
                      // onClick={e => e.preventDefault()}
                    >
                      AGB
                    </DropdownItem>
                    {/* <DropdownItem
                    tag={Link}
                   // target="_blank"
                      to="/admin/merkblatt"
                      // rel="noopener noreferrer"
                      // onClick={e => e.preventDefault()}
                    >
                      Merkblatt
                    </DropdownItem> */}
                    {/* <DropdownItem
                    tag={Link}
                      to="/admin/feedback"
                      // onClick={e => e.preventDefault()}
                    >
                      Feedback
                    </DropdownItem> */}
                    <DropdownItem
                      //href="/auth/login"
                      // onClick={e => e.preventDefault()}
                      onClick={this.logOut}
                    >
                      Abmelden
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <UncontrolledDropdown>
               <div className="photo" style={{
                 width: '34px',
                 height: '34px',
                 overflow: 'hidden',
                 float: 'left',
                 Zindex: 5,
                 marginRight: '12px',
                 borderRadius: '50%',
                 marginLeft: '5px'
               }}>
              <img src={avatar} alt="Avatar" />
            </div>
               </UncontrolledDropdown> */}
              </Nav>
             
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
