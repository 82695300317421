/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}>
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            {/* <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="https://hello-medics.guel.gmbh/impressum"
                    target="_blank">
                    Impressum
                  </a>
                </li>
                <li>
                  <a href="https://hello-medics.guel.gmbh/AGB" target="_blank">
                    AGB
                  </a>
                </li>
                <li>
                  <a
                    href="https://hello-medics.guel.gmbh/datenschutz"
                    target="_blank">
                    Datenschutz
                  </a>
                </li>
              </ul>
            </nav> */}
            <div className="credits ml-auto">
              <span className="copyright" style={{
            fontSize: '11px', color : '#FFFFFF'}} >
                <b> &copy; {1900 + new Date().getYear()} - HELLO-SISTER.DE Alle Rechte vorbehalten.</b>
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
