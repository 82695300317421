import React from 'react';
import { Spinner } from 'reactstrap';
import loadable from '@loadable/component';

const Dashboard = loadable(() => import("views/pages/dashboard/DashboardPage"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Login = loadable(() => import("views/pages/Login.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
// const Login1 = loadable(() => import("views/pages/Login1.js"), {
//   fallback:<div><Spinner color="success" /></div>,
// })
const Register1 = loadable(() => import("views/pages/registerr.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Referal = loadable(() => import("views/pages/Referal"), {
  fallback:<div className="spinner_full"><Spinner style={{ width: "5rem", height: "5rem"}} color="primary" /></div>,
})

const ReferalLast = loadable(() => import("views/pages/referal_msg.js"), {
  fallback:<div className="spinner_full"><Spinner style={{ width: "5rem", height: "5rem"}} color="primary" /></div>,
})
const ForgotPassword = loadable(() => import("views/pages/ForgotPassword.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
const ForgotPasswordFinal = loadable(() => import("views/pages/ForgotPasswordFinal.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
const AfterRegister = loadable(() => import("views/pages/AfterRegister.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
const ResetPassword = loadable(() => import("views/pages/ResetPassword.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
const JobDetails = loadable(() => import("views/pages/job/active/job_detail/doctor/JobDetails"), {
  fallback:<div><Spinner color="success" /></div>,
})
const JobDetails2 = loadable(() => import("building_block/job_details2"), {
  fallback:<div><Spinner color="success" /></div>,
})
const JobDetailsFront = loadable(() => import("building_block/job_details1"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Referal1 = loadable(() => import("views/pages/referal1"), {
  fallback:<div><Spinner color="success" /></div>,
})
const CandidateProfil = loadable(() => import("views/pages/profile/candidateprofile/CandidateProfile"), {
  fallback:<div><Spinner color="success" /></div>,
})
const CandidateProfil1 = loadable(() => import("views/pages/CandidateProfile1"), {
  fallback:<div><Spinner color="success" /></div>,
})
// const CandidateProfilFirst = loadable(() => import("views/pages/CandidateProfileFirst"), {
//   fallback:<div><Spinner color="success" /></div>,
// })
const Message = loadable(() => import("building_block/message/components/ConversationList"), {
  fallback:<div><Spinner color="success" /></div>,
})

const WorkExperience = loadable(() => import("views/pages/WorkExperience"), {
  fallback:<div><Spinner color="success" /></div>,
})

const WorkExperienceEdit = loadable(() => import("views/pages/WorkExperienceEdit"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Languages = loadable(() => import("views/pages/Languages"), {
  fallback:<div><Spinner color="success" /></div>,
})

const LanguagesEdit = loadable(() => import("views/pages/LanguagesEdit"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Education = loadable(() => import("views/pages/Education"), {
  fallback:<div><Spinner color="success" /></div>,
})

const EducationEdit = loadable(() => import("views/pages/EducationEdit"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Documents = loadable(() => import("views/pages/Documents"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Bewerbungen = loadable(() => import("views/pages/Bewerbungen"), {
  fallback:<div><Spinner color="success" /></div>,
})

const NewJobs = loadable(() => import("views/pages/NewJobs"), {
  fallback:<div><Spinner color="success" /></div>,
})

const NewJobs1 = loadable(() => import("views/pages/NewJobs1"), {
  fallback:<div><Spinner color="success" /></div>,
})
const FrontendJobs = loadable(() => import("views/pages/frontendJobs"), {
  fallback:<div><Spinner color="success" /></div>,
})

const FilterJobs = loadable(() => import("views/pages/FilterJobs"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Account = loadable(() => import("views/pages/Account"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Imprint = loadable(() => import("views/pages/Imprint"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Contact = loadable(() => import("views/pages/Contact"), {
  fallback:<div><Spinner color="success" /></div>,
})

const BerufundKompetenzen = loadable(() => import("views/pages/BerufundKompetenzen"), {
  fallback:<div><Spinner color="success" /></div>,
})



const Arzt = loadable(() => import("views/pages/Arzt"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Marklist = loadable(() => import("views/pages/MarkList"), {
  fallback:<div><Spinner color="success" /></div>,
})

const FinalDelete = loadable(() => import("views/pages/DeleteFinal"), {
  fallback:<div><Spinner color="success" /></div>,
})

const AccountDelete = loadable(() => import("views/pages/AccountDelete"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Archiviert = loadable(() => import("views/pages/Archiviert"), {
  fallback:<div><Spinner color="success" /></div>,
})

const JobDel = loadable(() => import("building_block/job_details/component"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Apply = loadable(() => import("building_block/job_details_Applied"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Mark = loadable(() => import("building_block/job_details_Marken"), {
  fallback:<div><Spinner color="success" /></div>,
})
const ApplyChat = loadable(() => import("building_block/job_details_chat"), {
  fallback:<div><Spinner color="success" /></div>,
})

const ML = loadable(() => import("building_block/message/components/MessageList"), {
  fallback:<div><Spinner color="success" /></div>,
})

const coming = loadable(() => import("Frontend/coming"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Video_main = loadable(() => import("video_call/pages/index"), {
  fallback:<div><Spinner color="success" /></div>,
})

const feedback = loadable(() => import("views/pages/feedback.jsx"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Video_meeting = loadable(() => import("video_call/pages/meeting"), {
  fallback:<div><Spinner color="success" /></div>,
})


const Home1 = loadable(() => import("Frontend1/Home"), {
  fallback:<div><Spinner color="success" /></div>,
})

const datenschutz = loadable(() => import("Frontend1/datenschutz")
, {
  fallback:<div><Spinner color="success" /></div>,
})

const facebook = loadable(() => import("Frontend1/facebook")
, {
  fallback:<div><Spinner color="success" /></div>,
})

const Impressum = loadable(() => import("Frontend1/Impressum"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Kontakt = loadable(() => import("Frontend1/contact"), {
  fallback:<div><Spinner color="success" /></div>,
})
const AGB = loadable(() => import("Frontend1/AGB"), {
  fallback:<div><Spinner color="success" /></div>,
})
const KandidateContractPage = loadable(() => import("views/pages/KandidateContractPage"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Merkblatt = loadable(() => import("views/pages/Merkblatt.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
//import Dashboard from "views/pages/dashboard/DashboardPage";
//import Login from "views/pages/Login.js";
//import Register1 from "views/pages/registerr.js";
//import ForgotPassword from "views/pages/ForgotPassword.js";
//import ForgotPasswordFinal from "views/pages/ForgotPasswordFinal.js";
//import AfterRegister from "views/pages/AfterRegister.js";
//import ResetPassword from "views/pages/ResetPassword.js";
//import JobDetails from "views/pages/job/active/job_detail/doctor/JobDetails";
//import JobDetails2 from "building_block/job_details2";
//import JobDetailsFront from "views/pages/job/active/job_detail/doctor/JobDetails1";

//import CandidateProfil from "views/pages/profile/candidateprofile/CandidateProfile";
//import Message from "building_block/message";

//import WorkExperience from "views/pages/WorkExperience"
//import WorkExperienceEdit from "views/pages/WorkExperienceEdit"
//import Languages from "views/pages/Languages"
//import LanguagesEdit from "views/pages/LanguagesEdit"
//import Education from "views/pages/Education"
//import EducationEdit from "views/pages/EducationEdit"
//import Documents from "views/pages/Documents"
//import Bewerbungen from "views/pages/Bewerbungen"
//import NewJobs from "views/pages/NewJobs"
//import NewJobs1 from "views/pages/NewJobs1"
//import FrontendJobs from "views/pages/frontendJobs"
//import FilterJobs from "views/pages/FilterJobs"
//import Account from "views/pages/Account"
//import Imprint from "views/pages/Imprint"
//import Contact from "views/pages/Contact"
//import BerufundKompetenzen from "views/pages/BerufundKompetenzen"
//import Arzt from "views/pages/Arzt"
//import Marklist from "views/pages/MarkList"
//import FinalDelete from "views/pages/DeleteFinal"
//import AccountDelete from "views/pages/AccountDelete"
//import Archiviert from "views/pages/Archiviert"
//import JobDel from "building_block/job_details/component"
//import AppComponents from "views/pages/AppComponents"
//import Apply from "building_block/job_details_Applied"
//import Mark from "building_block/job_details_Marken"
//import ApplyChat from "building_block/job_details_chat"
//import ML from "building_block/message/components/MessageList";
// import Home from "Frontend/Home";
//import Home1 from "Frontend1/Home";
//import datenschutz from "Frontend1/datenschutz";
//import Impressum from "Frontend1/Impressum";
//import coming from "Frontend/coming";
// import Datenschutz from "Frontend/Datenschutz";
// import Impressum from "Frontend/impressum";
//import Kontakt from "Frontend1/contact";
//import Error from "Frontend1/error";
//import Nutzungsbedingungen from "Frontend1/Nutzungsbedingungen";
//import Video_main from "video_call/pages/index";
//import feedback from "views/pages/feedback.jsx"
//import Video_meeting from "video_call/pages/meeting";


const routes = [
  
  {
    path: "/dashboardpage",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin"
  },

 

  
  {
    path: "/meeting",
    name: "meeting",
    icon: "nc-icon nc-camera",
    component: Video_meeting,
    layout: "/admin",
    invisible: true
  },

  
  {
    path: "/coming",
    name: "coming",
    component: coming,
    layout: "/auth",
    invisible: true
  },

  // {
  //   path: "/home",
  //   name: "home",
  //   component: Home,
  //   layout: "/auth",
  //   invisible: true
  // },

  {
    path: "/home",
    name: "home",
    component: Home1,
    layout: "/auth",
    invisible: true
  },
  

  {
    path: "/datenschutz",
    name: "datenschutz",
    component: datenschutz,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/facebook",
    name: "facebook",
    component: facebook,
    layout: "/auth",
    invisible: true
  },
  
  {
    path: "/Impressum",
    name: "Impressum",
    component: Impressum,
    layout: "/auth",
    invisible: true
  },

  // {
  //   path: "/Datenschutz",
  //   name: "Datenschutz",
  //   component: Datenschutz,
  //   layout: "/auth",
  //   invisible: true
  // },

  // {
  //   path: "/Impressum",
  //   name: "Impressum",
  //   component: Impressum,
  //   layout: "/auth",
  //   invisible: true
  // },

  {
    path: "/404",
    name: "error",
    component: Error,
    layout: "/auth",
    invisible: true
  },
  
  {
    path: "/Kontakt",
    name: "Kontakt",
    component: Kontakt,
    layout: "/auth",
    invisible: true
  },

  {
    path: "/AGB",
    name: "AGB",
    component: AGB,
    layout: "/auth",
    invisible: true
  },

  {
    path: "/login",
    name: "Login",
    icon: "nc-icon nc-calendar-60",
    component: Login,
    layout: "/auth",
    invisible: true
  },
  // {
  //   path: "/login1",
  //   name: "Login1",
  //   icon: "nc-icon nc-calendar-60",
  //   component: Login1,
  //   layout: "/auth",
  //   invisible: true
  // },
  {
    path: "/register",
    name: "Register",
    icon: "nc-icon nc-calendar-60",
    component: Register1,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    icon: "nc-icon nc-calendar-60",
    component: ForgotPassword,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/forgotpasswordfinal",
    name: "ForgotPasswordFinal",
    icon: "nc-icon nc-calendar-60",
    component: ForgotPasswordFinal,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    icon: "nc-icon nc-calendar-60",
    component: ResetPassword,
    layout: "/auth",
    invisible: true
  },
  {
    collapse: true,
    name: "Jobs",
    icon: "nc-icon nc-bank",
    state: "konto", 
    views: [

  
 
    ]
    ,
    invisible: true
  },
  
  {
    path: "/details-job/:job_id",
    name: "JobDetails",
    icon: "nc-icon nc-calendar-60",
    component: JobDetails,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/details-job2/:job_id",
    name: "JobDetails2",
    icon: "nc-icon nc-calendar-60",
    component: JobDetails2,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/jobs-stellenangebote/:job_id/:job_function",
    name: "JobDetailsFront",
    icon: "nc-icon nc-calendar-60",
    component: JobDetailsFront,
    layout: "/auth",
    invisible: true
  },
  // {
  //   path: "/candidate-profilee",
  //   name: "Profil",
  //   icon: "fas fa-user-tie",
  //   component: CandidateProfil,
  //   layout: "/admin"
  // },
  {
    path: "/candidate-profileee",
    name: "Profil",
    icon: "fas fa-user-tie",
    component: CandidateProfil1,
    layout: "/admin"
  },
  // {
  //   path: "/candidate-profile",
  //   name: "Mein Profil",
  //   icon: "fas fa-user-tie",
  //   component: CandidateProfilFirst,
  //   layout: "/admin",
  //   invisible : true
  // },



  {
    path: "/beruf",
    name: "Kompetenzen",
    icon: "fa fa-user-md",
    component: BerufundKompetenzen,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/StellenPräferenzen",
    name: "Präferenzen",
    icon: "fa fa-hospital",
    component: Arzt,
    layout: "/admin",
    invisible: true
  },
  {
    collapse: true,
    name: "Präferenzen",
    icon: "fa fa-hospital",
    state: "Explore",
    views: [
      {
         path: "/arzt",
         name: "Arzt",
         mini: "AR",
         component: Arzt,
         layout: "/admin"
       },

      
    ],
    invisible: true
  },
  
  
  
  {
    path: "/work-experience",
    name: "Arbeitserfahrung",
    icon: "fa fa-stethoscope",
    component: WorkExperience,
    layout: "/admin",
    invisible: true
  },
    
  {
    path: "/work-experience-edit/:exp_id",
    name: "Arbeitserfahrung",
    icon: "fa fa-stethoscope",
    component: WorkExperienceEdit,
    layout: "/admin",
    invisible:true
  },
  
  {
    path: "/education",
    name: "Ausbildung",
    icon: "fa fa-book",
    component: Education,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/education-edit/:edu_id",
    name: "AusbildungEdit",
    icon: "fa fa-book",
    component: EducationEdit,
    layout: "/admin",
    invisible:true
  },

  // {
  //   path: "/app-components",
  //   name: "AppComponents",
  //   icon: "nc-icon nc-email-85",
  //   component: AppComponents,
  //   layout: "/admin"
  // },

  {
    path: "/languages",
    name: "Sprachen",
    icon: "nc-icon nc-chat-33",
    component: Languages,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/languages-edit/:lang_id",
    name: "Sprachen Edit",
    icon: "nc-icon nc-chat-33",
    component: LanguagesEdit,
    layout: "/admin",
    invisible : true
  },
 
  {
    path: "/account",
    name: "Account",
    //icon: "nc-icon nc-chat-33",
    component: Account,
    layout: "/admin",
    invisible:"true"
  },
  {
    path: "/imprint",
    name: "Imprint",
    //icon: "nc-icon nc-chat-33",
    component: Imprint,
    layout: "/admin",
    invisible:"true"
  },
  {
    path: "/contact",
    name: "Contact",
    //icon: "nc-icon nc-chat-33",
    component: Contact,
    layout: "/admin",
    invisible:"true"
  },
  {
    path: "/documents",
    name: "Dokumente",
    icon: "fas fa-file",
    component: Documents,
    layout: "/admin",
    invisible: true
  },

{
  path: "/Bewerbungen",
  name: "Bewerbungen",
  //mini: "AB",
  icon: "fa fa-check-circle",
  component: Bewerbungen,
  layout: "/admin",
},
 
// {
//    collapse: true,
//    name: "Bewerbungen",
//    icon: "fa fa-check-circle",
//    state: "Exploree",
//    views: [
    
 
//     {
//       path: "/archiviert",
//       name: "Archiviert",
//       mini: "AV",
//       component: Archiviert,
//       layout: "/admin",
//     }
 
//   ]
//  },
  
 
  // {
  //   path: "/Merkliste",
  //   name: "Merkzettel",
  //   icon: "fas fa-bookmark",
  //   component: Marklist,
  //   layout: "/admin",
  //   //invisible: true
  // },
  // {
  //   path: "/Alle-Neue-Jobs",
  //   name: "Neue Jobs",
  //   icon: "fa fa-plus",
  //   component: NewJobs,
  //   layout: "/admin",
  //   //invisible: true
  // },
  {
    path: "/Alle-Neue-Jobs1",
    name: "Alle Neue Jobs 1",
    icon: "fa fa-plus",
    component: NewJobs1,
    layout: "/auth",
    invisible: true
  },
  // {
  //   path: "/job-stellenangebot/:searchListSelect/:city1",
  //   name: "Alle Neue Jobs",
  //   icon: "fa fa-plus",
  //   component: FrontendJobs,
  //   layout: "/auth",
  //   invisible: true
  // },

  {
    path: "/job-stellenangebot",
    name: "Alle Neue Jobs",
    icon: "fa fa-plus",
    component: FrontendJobs,
    layout: "/auth",
    invisible: true
  },
  // {
  //   path: "/Suche-nach-Filter",
  //   name: "Job Suchen",
  //   icon: "fa fa-search",
  //   component: FilterJobs,
  //   layout: "/admin",
  //   //invisible: true
  // },

  {
    collapse: true,
    name: "Kontaktanfragen",
    icon: "fa fa-tty",
    state: "Exploreee",
    views: [

   
    ],
    invisible : true
  },

  {
    path: "/details-job/:job_id",
    name: "details-job",
    icon: "nc-icon nc-calendar-60",
    component: JobDel,
    layout: "/admin",
    invisible: true
  },


  {
    path: "/apply-job/:job_id",
    name: "apply",
    icon: "nc-icon nc-calendar-60",
    component: Apply,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/details-jobs/:job_id",
    name: "ChatDetails",
    icon: "nc-icon nc-calendar-60",
    component: ApplyChat,
    layout: "/admin",
    invisible: true,
  },

  {
    path: "/marklist/:job_id",
    name: "marklist",
    icon: "nc-icon nc-calendar-60",
    component: Mark,
    layout: "/admin",
    invisible: true,
  },

  {
    path: "/welcome",
    name: "After Register",
    icon: "nc-icon nc-calendar-60",
    component: AfterRegister,
    layout: "/auth",
    invisible: true
    
  },
  {
    path: "/accountdelete",
    name: "Account Delete",
    icon: "nc-icon nc-calendar-60",
    component: AccountDelete,
    layout: "/admin",
    invisible: true 
  },
  {
    path: "/delete-final",
    name: "Delete Final",
    icon: "nc-icon nc-calendar-60",
    component: FinalDelete,
    layout: "/auth",
    invisible: true   
  },
  {
    path: "/message/",
    name: "Chat",
    icon: "nc-icon nc-chat-33",
    component: Message,
    layout: "/admin",
    //invisible: true
  },
  // {
  //   path: "/messages-list/:job_id/:hosp_id",
  //   name: "Chat",
  //   icon: "nc-icon nc-chat-33",
  //   component: ML,
  //   layout: "/admin",
  //   invisible: false
  // },
  {
    path: "/messages-list/",
    name: "Chat",
    icon: "nc-icon nc-chat-33",
    component: ML,
    layout: "/admin",
    invisible: true
  },

  {
    path: "/video_main",
    name: "video Call",
    icon: "fas fa-video",
    component: Video_main,
    layout: "/admin",
    invisible: true
  },
 


  {
    path: "/feedback",
    name: "feedback",
    component: feedback,
    icon: "fas fa-comment-alt",
    layout: "/admin",
   // invisible: true
  },
  {
    path: "/referal",
    name: "Freunde werben",
    icon: "nc-icon nc-send",
    component: Referal,
    layout: "/admin",
    invisible:false
  },
          {
        path: "/referals1",
        name: "Freunde werben",
        icon: "fas fa-user-friends",
        component: Referal1,
        layout: "/admin",
       invisible: true
      },
  {
    path: "/merkblatt",
    name: "Merkblatt",
    //icon: "nc-icon nc-send",
    component: Merkblatt,
    layout: "/admin",
    invisible:"true"
  },
  {
    path: "/referallast",
    name: "Freunde werben",
    icon: "nc-icon nc-send",
    component: ReferalLast,
    layout: "/admin",
    invisible:"true"
  },
  {
    path: "/KandidateContractPage/:applyjob_id",
    name: "KandidateContractPage",
    icon: "nc-icon nc-money-coins",
    component: KandidateContractPage,
    layout: "/admin",
    invisible: true
  },

];

export default routes;

