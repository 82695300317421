import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import loadable from '@loadable/component';
import { Spinner } from 'reactstrap';

import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/style-custom.css";
import "assets/frontend1/css/bootstrap.min.css";
import "assets/frontend1/css/all.css";
import "assets/frontend1/css/slick.css";
import "assets/frontend1/css/slick-theme.css";
import "assets/frontend1/css/magnific-popup.css";
import "assets/frontend1/css/style.css";

import "assets/frontend2/css/bootstrap.min.css";
import "assets/frontend2/css/magnific-popup.css";
import "assets/frontend2/css/meanmenu.css";
import "assets/frontend2/css/nice-select.css";
import "assets/frontend2/css/style.css";
import "assets/frontend2/css/responsive.css";
import "assets/frontend/css/style1.css"
import "assets/frontend2/fontawesome/css/all.css"

import * as serviceWorker from './serviceWorker';
import { subscribeUser } from './subscription';

const hist = createBrowserHistory();

// const coming = loadable(() => import("Frontend/coming.js"), {
//   fallback:<div><Spinner color="success" /></div>,
// })


const Error = loadable(() => import("Frontend1/error.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
const profile = loadable(() => import("Frontend1/profile.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Kompetenzen = loadable(() => import("Frontend1/Kompetenzen.js"), {
  fallback:<div><Spinner color="success" /></div>,
})
const preferences = loadable(() => import("Frontend1/preferences.js"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Home = loadable(() => import("Frontend1/Home2"), {
  fallback:<div><Spinner color="success" /></div>,
})
const user_reg = loadable(() => import("Frontend1/user_reg.js"), {
  fallback:<div><Spinner color="success" /></div>,
})

const jobsSearch = loadable(() => import("Frontend1/jobs-suchen"), {
  fallback:<div><Spinner color="success" /></div>,
})

const Login = loadable(() => import("./views/pages/Login"), {
  fallback:<div><Spinner color="success" /></div>,
}) 
const Login1 = loadable(() => import("./views/pages/Login1"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Register = loadable(() => import("./views/pages/registerr"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Refregister = loadable(() => import("./views/pages/refregister"), {
  fallback:<div><Spinner color="success" /></div>,
}) 
const ForgetPasswordFinal = loadable(() => import("./views/pages/ForgotPasswordFinal"), {
  fallback:<div><Spinner color="success" /></div>,
}) 
const ForgetPassword = loadable(() => import("./views/pages/ForgotPassword"), {
  fallback:<div><Spinner color="success" /></div>,
}) 
const ResetPassword = loadable(() => import("./views/pages/ResetPassword"), {
  fallback:<div><Spinner color="success" /></div>,
}) 
const AfterRegister = loadable(() => import("./views/pages/AfterRegister"), {
  fallback:<div><Spinner color="success" /></div>,
}) 

const Delete = loadable(() => import("./views/pages/DeleteFinal"), {
  fallback:<div><Spinner color="success" /></div>,
}) 
const Datenschutz = loadable(() => import("Frontend1/datenschutz")
, {
  fallback:<div><Spinner color="success" /></div>,
}
)
const Facebook = loadable(() => import("Frontend1/facebook"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Impressum = loadable(() => import("Frontend1/Impressum"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Kontakt = loadable(() => import("Frontend1/contact"), {
  fallback:<div><Spinner color="success" /></div>,
})
const AGB = loadable(() => import("Frontend1/AGB"), {
  fallback:<div><Spinner color="success" /></div>,
})
const AGB_Older = loadable(() => import("Frontend1/AGB_Older"), {
  fallback:<div><Spinner color="success" /></div>,
})
const AGB_Older_candidate = loadable(() => import("Frontend1/AGB_Older_candidate"), {
  fallback:<div><Spinner color="success" /></div>,
})
const Daten_old = loadable(() => import("Frontend1/datenschutz_older"), {
  fallback:<div><Spinner color="success" /></div>,
})
const FAQ = loadable(() => import("Frontend1/faq"), {
  fallback:<div><Spinner color="success" /></div>,
})
const JobDetailsFront = loadable(() => import("building_block/job_details1"), {
  fallback:<div><Spinner color="success" /></div>,
})
const NewJobs1 = loadable(() => import("views/pages/NewJobs1"), {
  fallback:<div><Spinner color="success" /></div>,
})
const FrontendJobs = loadable(() => import("views/pages/frontendJobs"), {
  fallback:<div><Spinner color="success" /></div>,
})
const About_us = loadable(() => import("Frontend1/About_us"), {
  fallback:<div><Spinner color="success" /></div>,
})

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/wRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c/:username' component={profile} /> 
      <Route exact path='/G4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQSflKx/:user_id' component={Kompetenzen} /> 
      <Route exact path='/JzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikpva/:user_id' component={preferences} /> 
      <Route exact path='/pvvtfdknsplkaioahpyxqhblpvpqsxpggvnjycvyccbpzllslqztdsaqjinltabqevvcdmsybcobunftopvelcjhjydhgpuzccop/:email' component={user_reg} /> 

      <Route exact path='/jobs-suchen' component={jobsSearch} /> 
     
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
     
      <Route  path='/login' component={Login} />
      <Route  path='/faq' component={FAQ} />
      <Route  path='/UberUns' component={About_us} />
      <Route  path='/login1/' component={Login1} />
      <Route path='/register' component={Register} />
      <Route  path='/refregister/:code' component={Refregister} />
      <Route  path='/forgotpassword' component={ForgetPassword} />
      <Route  path='/forgotpasswordfinal' component={ForgetPasswordFinal} />
      <Route  path='/resetpassword/:email' component={ResetPassword} />
      <Route  path='/welcome' component={AfterRegister} />
      <Route  path='/delete-final' component={Delete} />
      <Route  path='/Datenschutz' component={Datenschutz} />
      <Route  path='/Impressum' component={Impressum} />
      <Route  path='/Kontakt' component={Kontakt} />
      <Route  path='/AGB' component={AGB} />
      <Route  path='/archived_AGB/:AGB_id' component={AGB_Older} />
      <Route  path='/archived_AGB_Kandidaten/:AGB_id' component={AGB_Older_candidate} />
      <Route  path='/archived_datenschutz/:daten_id' component={Daten_old} />
      <Route  path='/facebook' component={Facebook} />
      <Route  path='/jobs-stellenangebote/:job_id/:job_function' component={JobDetailsFront} />
      <Route  path='/Alle-Neue-Jobs1' component={NewJobs1} />
      <Route  path='/job-stellenangebot' component={FrontendJobs} />
      <Route path="/404" component={Error} />
      <Redirect to="/404" />
    
    </Switch>
  </Router>,
  document.getElementById("root")
);
serviceWorker.register();
subscribeUser()
